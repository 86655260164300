
import {computed, defineComponent} from "vue";
import ConceptAddNew from "@/components/hotel/ConceptAddNew.vue";
import ConceptUpdate from "@/components/hotel/ConceptUpdate.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {hideModal} from "@/core/helpers/dom";

export default defineComponent({
  name: "kt-widget-10",
  components: {
    ConceptAddNew,
    ConceptUpdate,
  },
  data: function () {
    return {
      myConceptID : 0,
    }
  },
  methods: {
    setConceptID(ID){
      this.myConceptID = ID;
    },

  },
  props: {
    widgetClasses: String,
    hotelID: String,
  },
  setup(props) {
    const payload = {
      HotelID: props.hotelID
    }
    store.dispatch(Actions.HOTEL_CONCEPTS_LIST, payload);
    const myConcepts = computed(() => {
      return store.getters.hotelConceptsList;
    });
    const deleteConcept = (ID) => {

      Swal.fire({
        title: 'Eminmisiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload1 = {
            ID: ID,
            HotelID: props.hotelID
          }
          console.log(payload1);
          store.dispatch(Actions.HOTEL_CONCEPTS_DELETE, payload1)
              .then(() => {
                Swal.fire(
                  'Silindi!',
                  'Kayit silme işlemi tamamlandı.',
                  'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getHotelConceptsErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });
    };
    return {
      myConcepts,
      deleteConcept,
    };
  },
});
