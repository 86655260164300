
import {computed, defineComponent, ref} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "new-factsheet-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    factsheetID: String,
  },
  computed:{
    myFactSheetDetails(){
      return store.getters.hotelFactSheetsDetails;
    }
  },
  watch:{
    factsheetID(){
      const payload = {
        ID: this.factsheetID
      }
      store.dispatch(Actions.HOTEL_FACTSHEETS_DETAILS, payload);
    }
  },
  setup() {

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const updateFactSheetModalRef = ref<null | HTMLElement>(null);
    const factsheetActive = ref(null)


    store.dispatch(Actions.MARKETS_LIST_CACHE, {});
    store.dispatch(Actions.LANGUAGES_LIST_CACHE, {});

    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });
    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });
    const myLanguages = computed(() => {
      return store.getters.LanguagesListCache;
    });

    const myFactSheetDetails = computed(() => {
      return store.getters.hotelFactSheetsDetails;
    });


    const validationSchema = Yup.object().shape({
      factsheetTitle: Yup.string().required().label("Fact-Sheet İsmi"),
      factsheetMarket: Yup.string().required().label("Market"),
      factsheetLanguage: Yup.string().required().label("Language"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let isActive = (factsheetActive.value.checked) ? 'active' : 'passive';

      const payload1 = {
        ID: myFactSheetDetails.value.ID,
        HotelID: myFactSheetDetails.value.HotelID,
        Title: myFactSheetDetails.value.Title,
        Market: myFactSheetDetails.value.Market,
        Language: myFactSheetDetails.value.Language,
        is_active: isActive,
      }
      store.dispatch(Actions.HOTEL_FACTSHEETS_EDIT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              //Disable button
              submitButtonRef.value.disabled = false;
              // Activate indicator
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(updateFactSheetModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getUserErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });


    };

    return {
      myDetails2,
      myMarkets,
      myLanguages,
      factsheetActive,
      validationSchema,
      submit,
      submitButtonRef,
      updateFactSheetModalRef,
    };
  },
});
