
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

interface NewRoomData {
  roomTitle: string;
  roomCategory: string;
  roomADT: string;
  roomCHD: string;
  roomSale: string;
  roomActive: string;
}

export default defineComponent({
  name: "new-room-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newRoomModalRef = ref<null | HTMLElement>(null);
    const roomSale = ref(null)
    const roomActive = ref(null)


    store.dispatch(Actions.HOTEL_ROOM_TYPES_LIST, {});
    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });
    const myRoomTypes = computed(() => {
      return store.getters.hotelRoomTypesList;
    });


    const newRoomData = ref<NewRoomData>({
      roomTitle: "",
      roomCategory: "",
      roomADT: "",
      roomCHD: "",
      roomSale: "",
      roomActive: "",
    });

    const validationSchema = Yup.object().shape({
      roomTitle: Yup.string().required().label("Oda İsmi"),
      roomCategory: Yup.string().required().label("Oda Kategory"),
      roomADT: Yup.string().required().label("Yetişkin Kapasite"),
    });
    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let isActive = (roomActive.value.checked) ? 'active' : 'passive';
      let isSale = (roomSale.value.checked) ? 'open' : 'stop';

      const payload1 = {
        HotelID: myDetails2.value.ID,
        Title: newRoomData.value.roomTitle,
        TypeID: newRoomData.value.roomCategory,
        AdultMax: newRoomData.value.roomADT,
        ChildMax: newRoomData.value.roomCHD,
        PaxCombination: filteredCom(),
        is_sale: isSale,
        is_active: isActive,
      }
      store.dispatch(Actions.HOTEL_ROOMS_INSERT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newRoomModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getUserErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });

    };

    let myUnSelectedCapacity = [];
    const arrPaxCom = computed(() => {
      let myADT = Number(newRoomData.value.roomADT);
      let myCHD = Number(newRoomData.value.roomCHD);
      let start = 1;
      let end = myADT+(myADT*myCHD);
      const array1= Array.from({length: (myADT+1) - start}, (v, k) => (k + start) + "ADT");
      const array2=Array.from({length: (myCHD+1) - start}, (v, k) => (k + start) + "CHD");
      return array1.concat(array1.flatMap(d => array2.map(v => d + "+" + v)));
    });

    const capacityCom = (e) => {
      if(!e.target.checked && !myUnSelectedCapacity.includes(e.target.value)){
        myUnSelectedCapacity.push(e.target.value);
      }else{
        let delKey = Object.keys(myUnSelectedCapacity).find(key => myUnSelectedCapacity[key] === e.target.value)
        myUnSelectedCapacity.splice(Number(delKey),1);
      }
    }

    const filteredCom = () => {
      if (myUnSelectedCapacity != null) {
        return arrPaxCom.value.filter(item =>
            !myUnSelectedCapacity.includes(item)
        );
      } else {
        return true;
      }
    };
    return {
      myDetails2,
      myRoomTypes,
      newRoomData,
      roomActive,
      roomSale,
      validationSchema,
      submit,
      submitButtonRef,
      newRoomModalRef,
      capacityCom,
      arrPaxCom,
    };
  },
});
