
import {computed, defineComponent, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {ErrorMessage, Field, Form} from "vee-validate";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import MasterConfig from "@/core/config/MasterConfig";
import {dateConvert} from "@/core/filters/datatime";
import {commonf} from "@/core/filters/common";
import RoomList from "@/components/hotel/RoomList.vue";
import ConceptList from "@/components/hotel/ConceptList.vue";
import FactSheetList from "@/components/hotel/FactSheetList.vue";
import FileList from "@/components/common/FileList.vue";
import HotelPriceAction from "@/components/hotel/HotelPriceAction.vue";

export default defineComponent({
    name: "account-settings",
    data: function () {
        return {
        }
    },
    components: {
        RoomList,
        ConceptList,
        FactSheetList,
        ErrorMessage,
        Field,
        Form,
        FileList,
        HotelPriceAction,
    },
    methods: {
        ...dateConvert.method,
        ...commonf
    },
    computed: {},
    setup() {
        const route = useRoute();
        const id = route.params.id;
        const payload = {ID: id}
        let today = new Date().toLocaleDateString()
        today = (dateConvert.method.convertToDateYM(today));

        store.dispatch(Actions.HOTEL_DETAILS, payload);
        store.dispatch(Actions.HOTEL_CHAIN_LIST, {});
        store.dispatch(Actions.TRANSFER_LOCATIONS_LIST, {});

        const myDetails2 = computed(() => {
            return store.getters.hotelDetails;
        });
        const myHotelChainList = computed(() => {
            return store.getters.HotelChainList;
        });

        const myTransferLocations = computed(() => {
            return store.getters.transferLocationsList;
        })

        const submitButton1 = ref<HTMLElement | null>(null);
        const submitButton2 = ref<HTMLElement | null>(null);
        const submitButton3 = ref<HTMLElement | null>(null);
        const submitButton4 = ref<HTMLElement | null>(null);
        const deactivateHotelButton = ref<HTMLElement | null>(null);
        const hotelSale = ref(null)
        const hotelActive = ref(null)

        let isActive;
        let isSale;

        let isHotelSale = computed(() => {
            return (myDetails2.value.is_sale == 'open') ? true : false;
        });

        let isHotelActive = computed(() => {
            return (myDetails2.value.is_active == 'active') ? true : false;
        });

        const profileDetailsValidator = Yup.object().shape({
            hotelTitle: Yup.string().required().label("İsim"),
            hotelCity: Yup.string().required().label("Şehir"),
            hotelEmail: Yup.string().required().email().label("Email"),
            hotelWeb: Yup.string().required().label("Web sitesi"),
            hotelPhone: Yup.string().required().label("Telefon"),
            hotelFax: Yup.string().required().label("Fax"),
        });


        const deactiveHotel = Yup.object().shape({
            deactivate: Yup.string().required().label("Hesap Silme Onayı"),
        });



        const saveChanges1 = () => {
            if (submitButton1.value) {
                // Activate indicator
                submitButton1.value.setAttribute("data-kt-indicator", "on");
                const payload1 = {
                    ID: myDetails2.value.ID,
                    Title: myDetails2.value.Title,
                    City: myDetails2.value.City,
                    Country: myDetails2.value.Country,
                    Address: myDetails2.value.Address,
                    Email: myDetails2.value.Email,
                    Web: myDetails2.value.Web,
                    Phone: myDetails2.value.Phone,
                    Fax: myDetails2.value.Fax,
                    ChainID: myDetails2.value.ChainID,
                }
                console.log(payload1);

                store.dispatch(Actions.HOTEL_EDIT, payload1)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            //router.push({name: "userlist"});
                        });
                        submitButton1.value?.removeAttribute("data-kt-indicator");
                    }).catch(() => {
                    Swal.fire({
                        text: store.getters.getUserErrors[0],
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Tekrar dene!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        },
                    });
                    submitButton1.value?.removeAttribute("data-kt-indicator");
                });
            }
        };

        const saveChanges2 = () => {
            if (submitButton2.value) {
                // Activate indicator
                submitButton2.value.setAttribute("data-kt-indicator", "on");

                const payload2 = {
                    ID: myDetails2.value.ID,
                    ReservationEmail: myDetails2.value.ReservationEmail,
                    ReservationPhone: myDetails2.value.ReservationPhone,
                    SaleEmail: myDetails2.value.SaleEmail,
                    SalePhone: myDetails2.value.SalePhone,
                    OperationEmail: myDetails2.value.OperationEmail,
                    OperationPhone: myDetails2.value.OperationPhone,
                }

                store.dispatch(Actions.HOTEL_EDIT, payload2)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            //router.push({name: "userlist"});
                        });
                        submitButton2.value?.removeAttribute("data-kt-indicator");
                    })
                    .catch(() => {
                        Swal.fire({
                            text: store.getters.getUserErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                        submitButton2.value?.removeAttribute("data-kt-indicator");
                    });

            }
        };

        const saveChanges3 = () => {
            if (submitButton3.value) {
                submitButton3.value.setAttribute("data-kt-indicator", "on");
                const payload3 = {
                    ID: myDetails2.value.ID,
                    ParentRegionID: myDetails2.value.ParentRegionID,
                    RegionID: myDetails2.value.RegionID,
                    ChildRegionID: myDetails2.value.ChildRegionID,
                    TransferLocation: myDetails2.value.TransferLocation,

                }
                console.log(payload3);
                store.dispatch(Actions.HOTEL_EDIT, payload3)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            //router.push({name: "userlist"});
                        });
                        submitButton3.value?.removeAttribute("data-kt-indicator");
                    })
                    .catch(() => {
                        Swal.fire({
                            text: store.getters.getUserErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                        submitButton3.value?.removeAttribute("data-kt-indicator");
                    });

            }
        };

        const saveChanges4 = () => {
            if (submitButton4.value) {
                // Activate indicator
                submitButton4.value.setAttribute("data-kt-indicator", "on");
                isActive = (hotelActive.value.checked) ? 'active' : 'passive';
                isSale = (hotelSale.value.checked) ? 'open' : 'stop';

                const payload2 = {
                    ID: myDetails2.value.ID,
                    is_active: isActive,
                    is_sale: isSale,
                }

                store.dispatch(Actions.HOTEL_EDIT, payload2)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            //router.push({name: "userlist"});
                        });
                        submitButton4.value?.removeAttribute("data-kt-indicator");
                    })
                    .catch(() => {
                        Swal.fire({
                            text: store.getters.getUserErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                        submitButton4.value?.removeAttribute("data-kt-indicator");
                    });

            }
        };

        const deactivateHotel = () => {
            if (deactivateHotelButton.value) {
                // Activate indicator
                deactivateHotelButton.value.setAttribute("data-kt-indicator", "on");

                const payload6 = {
                    ID: myDetails2.value.ID,
                }
                console.log(payload6);
                store.dispatch(Actions.HOTEL_DELETE, payload6)
                    .then(() => {
                        Swal.fire({
                            text: "Tebrikler. Başarılı bir şekilde silme işlemini tamamladınız.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Geri Dön!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully delete
                            //router.push({name: "userlist"});
                            //this.$router.push({name: "hotellist"});
                        });
                        deactivateHotelButton.value?.removeAttribute("data-kt-indicator");
                    })
                    .catch(() => {
                        Swal.fire({
                            text: store.getters.getUserErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                        deactivateHotelButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
        };



        onMounted(() => {
            setCurrentPageBreadcrumbs("Özellikler", ["Oteller"]);
        });


        store.dispatch(Actions.REGIONS_LIST, {});
        const myRegionsList = computed(() => {
            return store.getters.RegionsList;
        });

        const filteredParentRegions = computed(() => {
            if (myRegionsList.value.data != null  && myDetails2.value.Country!==undefined && myDetails2.value.Country!=null) {

                return (myRegionsList.value.data).filter(parentRegionsItem =>
                    (parentRegionsItem.Country).toLowerCase() == (myDetails2.value.Country).toLowerCase() &&
                    (parentRegionsItem.ParentID == "null" || parentRegionsItem.ParentID == 0)
                );
            } else {
                return true;
            }
        });

        const filteredRegions = computed(() => {
            if (myRegionsList.value.data != null) {
                return (myRegionsList.value.data).filter(regionsItem =>
                    regionsItem.ParentID == myDetails2.value.ParentRegionID
                );
            } else {
                return true;
            }
        });
        const filteredChildRegions = computed(() => {
            if (myRegionsList.value.data != null) {
                return (myRegionsList.value.data).filter(childRegionsItem =>
                    childRegionsItem.ParentID == myDetails2.value.RegionID
                );
            } else {
                return true;
            }
        });


        store.dispatch(Actions.CITY_LIST, {});
        const myCities = computed(() => {
            return store.getters.CityList;
        });
        const filteredCity = computed(() => {
            if (myCities.value.data != null && myDetails2.value.Country!==undefined) {
                return (myCities.value.data).filter(citiesItem =>
                    citiesItem.Country == (myDetails2.value.Country).toLowerCase()
                );
            } else {
                return true;
            }
        });



        return {
            id,
            myDetails2,
            myHotelChainList,
            isHotelSale,
            isHotelActive,
            myCities,
            myRegionsList,
            filteredCity,
            filteredParentRegions,
            filteredRegions,
            filteredChildRegions,
            MasterConfig,
            hotelActive,
            hotelSale,
            submitButton1,
            submitButton2,
            submitButton3,
            submitButton4,
            saveChanges1,
            saveChanges2,
            saveChanges3,
            saveChanges4,
            deactivateHotel,
            profileDetailsValidator,
            deactiveHotel,
            deactivateHotelButton,
            myTransferLocations,
        };
    },
});
