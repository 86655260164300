
import {computed, defineComponent, ref} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

interface NewConceptData {
  conceptTitle: string;
  conceptCategory: string;
  conceptShortTitle: string;
  conceptSale: string;
  conceptActive: string;
}
export default defineComponent({
  name: "new-concept-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newConceptModalRef = ref<null | HTMLElement>(null);
    const conceptSale = ref(null)
    const conceptActive = ref(null)
    const conceptCategory = ref(null)


    store.dispatch(Actions.HOTEL_CONCEPT_TYPES_LIST, {});
    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });
    const myConceptTypes = computed(() => {
      return store.getters.hotelConceptTypesList;
    });



    const newConceptData = ref<NewConceptData>({
      conceptTitle: "",
      conceptCategory: "",
      conceptShortTitle: "",
      conceptSale: "",
      conceptActive: "",
    });

    const validationSchema = Yup.object().shape({
      conceptTitle: Yup.string().required().label("Konsept İsmi"),
      conceptCategory: Yup.string().required().label("Konsept Kategory"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let isActive = (conceptActive.value.checked) ? 'active' : 'passive';
      let isSale = (conceptSale.value.checked) ? 'open' : 'stop';

      const payload1 = {
        HotelID: myDetails2.value.ID,
        Title: newConceptData.value.conceptTitle,
        TypeID: newConceptData.value.conceptCategory,
        ShortTitle: newConceptData.value.conceptShortTitle,
        is_sale: isSale,
        is_active: isActive,
      }

      store.dispatch(Actions.HOTEL_CONCEPTS_INSERT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              // Activate indicator
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newConceptModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getUserErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        // Activate indicator
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });
    };
    const setOption = (e) => {
      newConceptData.value.conceptShortTitle = e.target.selectedOptions[0].dataset.short;
    }
    return {
      myDetails2,
      myConceptTypes,
      newConceptData,
      conceptActive,
      conceptSale,
      conceptCategory,
      validationSchema,
      submit,
      submitButtonRef,
      newConceptModalRef,
      setOption,
    };
  },
});
