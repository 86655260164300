
import { defineComponent, onMounted, ref } from "vue";
import Dropdown from "@/components/hotel/components/RoomPriceActionMenu.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "hotel-price-action",
  components: {
    Dropdown,
  },
  props: {
    widgetClasses: String,
    widgetColor: String,
    hotelID: String,
  },
  setup() {


    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {

    };
  },
});
