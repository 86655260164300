
import {computed, defineComponent, ref} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "new-concept-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    conceptID: String,
  },
  computed:{
    myConceptDetails(){
      return store.getters.hotelConceptsDetails;
    }
  },
  watch:{
    conceptID(){
      const payload = {
        ID: this.conceptID
      }
      store.dispatch(Actions.HOTEL_CONCEPTS_DETAILS, payload);
    }
  },
  setup() {

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const updateConceptModalRef = ref<null | HTMLElement>(null);
    const conceptSale = ref(null)
    const conceptActive = ref(null)


    store.dispatch(Actions.HOTEL_CONCEPT_TYPES_LIST, {});

    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });
    const myConceptTypes = computed(() => {
      return store.getters.hotelConceptTypesList;
    });
    const myConceptDetails = computed(() => {
      return store.getters.hotelConceptsDetails;
    });


    const validationSchema = Yup.object().shape({
      conceptTitle: Yup.string().required().label("Konsept İsmi"),
      conceptCategory: Yup.string().required().label("Konsept Kategory"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let isActive = (conceptActive.value.checked) ? 'active' : 'passive';
      let isSale = (conceptSale.value.checked) ? 'open' : 'stop';

      const payload1 = {
        ID: myConceptDetails.value.ID,
        HotelID: myConceptDetails.value.HotelID,
        Title: myConceptDetails.value.Title,
        TypeID: myConceptDetails.value.TypeID,
        ShortTitle: myConceptDetails.value.ShortTitle,
        is_sale: isSale,
        is_active: isActive,
      }
      store.dispatch(Actions.HOTEL_CONCEPTS_EDIT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              //Disable button
              submitButtonRef.value.disabled = false;
              // Activate indicator
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(updateConceptModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getUserErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });


    };
    const setOption = (e) => {
      myConceptDetails.value.ShortTitle = e.target.selectedOptions[0].dataset.short;
    }
    return {
      myDetails2,
      myConceptTypes,
      conceptActive,
      conceptSale,
      validationSchema,
      submit,
      submitButtonRef,
      updateConceptModalRef,
      setOption,
    };
  },
});
