
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "new-room-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data: function () {
    return {
      updateStatus: false,
    }
  },
  props: {
    roomID: String,
  },
  computed: {
    myRoomDetails() {
      return store.getters.hotelRoomsDetails;
    }
  },
  watch: {
    roomID() {
      this.updateStatus = false;
      const payload = {
        ID: this.roomID
      }
      store.dispatch(Actions.HOTEL_ROOMS_DETAILS, payload)
          .then(() => {
            this.updateStatus = true;
          })
    }
  },
  setup() {

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const updateRoomModalRef = ref<null | HTMLElement>(null);
    const roomSale = ref(null)
    const roomActive = ref(null)

    store.dispatch(Actions.HOTEL_ROOM_TYPES_LIST, {});

    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });
    const myRoomTypes = computed(() => {
      return store.getters.hotelRoomTypesList;
    });

    const myRoomDetails = computed(() => {
      return store.getters.hotelRoomsDetails;
    });


    const validationSchema = Yup.object().shape({
      roomTitle: Yup.string().required().label("Oda İsmi"),
      roomCategory: Yup.string().required().label("Oda Kategory"),
      roomADT: Yup.string().required().label("Yetişkin Kapasite"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let isActive = (roomActive.value.checked) ? 'active' : 'passive';
      let isSale = (roomSale.value.checked) ? 'open' : 'stop';

      const payload1 = {
        ID: myRoomDetails.value.ID,
        HotelID: myRoomDetails.value.HotelID,
        Title: myRoomDetails.value.Title,
        TypeID: myRoomDetails.value.TypeID,
        PaxCombination: filteredCom(),
        AdultMax: myRoomDetails.value.AdultMax,
        ChildMax: myRoomDetails.value.ChildMax,
        is_sale: isSale,
        is_active: isActive,
      }

            store.dispatch(Actions.HOTEL_ROOMS_EDIT, payload1)
                .then(() => {
                  Swal.fire({
                    text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "Geri Dön!",
                    customClass: {
                      confirmButton: "btn fw-bold btn-light-primary",
                    },
                  }).then(function () {
                    //Disable button
                    submitButtonRef.value.disabled = false;
                    // Activate indicator
                    submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                    // Go to page after successfully login
                    //router.push({name: "userlist"});
                  });
                }).catch(() => {
              Swal.fire({
                text: store.getters.getUserErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Tekrar dene!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });

    };
    let myUnSelectedCapacity = [];
    let myCheckedStatus = [];

    const arrPaxCom = computed(() => {

      let myADT = Number(myRoomDetails.value.AdultMax);
      let myCHD = Number(myRoomDetails.value.ChildMax);
      let start = 1;
      const array1 = Array.from({length: (myADT + 1) - start}, (v, k) => (k + start) + "ADT");
      const array2 = Array.from({length: (myCHD + 1) - start}, (v, k) => (k + start) + "CHD");
      const rsArray = array1.concat(array1.flatMap(d => array2.map(v => d + "+" + v)));

      setUnselected(rsArray);
/*
      console.log(myRoomDetails.value.PaxCombination);
      console.log(myUnSelectedCapacity);
      console.log(myCheckedStatus);
*/
      return rsArray;
    });

    const setUnselected = (list) => {
      myUnSelectedCapacity = [];
      if (myRoomDetails.value.PaxCombination !== undefined && (typeof myRoomDetails.value.PaxCombination!='string')) {
        myRoomDetails.value.PaxCombination = JSON.parse(myRoomDetails.value.PaxCombination);
      }
      for (let i = 0; i < list.length; i++) {
        if (!myRoomDetails.value.PaxCombination.includes(list[i])) {
          myUnSelectedCapacity.push(list[i]);
          myCheckedStatus[list[i]] = false;
        } else {
          myCheckedStatus[list[i]] = true;
        }
      }
    }

    const capacityCom = (e) => {
      if (!e.target.checked) {
        myUnSelectedCapacity.push(e.target.value);
      } else {
        let delKey = Object.keys(myUnSelectedCapacity).find(key => myUnSelectedCapacity[key] === e.target.value)
        myUnSelectedCapacity.splice(Number(delKey), 1);
      }
    }
    const chkStatus = (item) => {
      if (myRoomDetails.value.PaxCombination.includes(item)) {
        return true;
      } else {
        return false;
      }
    }

    const filteredCom = () => {
      if (myUnSelectedCapacity != null) {
        return arrPaxCom.value.filter(item =>
            !myUnSelectedCapacity.includes(item)
        );
      } else {
        return true;
      }
    };
    return {
      myDetails2,
      myRoomTypes,
      roomActive,
      roomSale,
      validationSchema,
      submit,
      submitButtonRef,
      updateRoomModalRef,
      capacityCom,
      myCheckedStatus,
      arrPaxCom,
      chkStatus,
      myUnSelectedCapacity,
    };
  },
});
