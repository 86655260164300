
import {computed, defineComponent, ref} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

interface NewFactSheetData {
  factsheetTitle: string;
  factsheetMarket: string;
  factsheetLanguage: string;
  factsheetActive: string;
}
export default defineComponent({
  name: "new-factsheet-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newFactSheetModalRef = ref<null | HTMLElement>(null);
    const factsheetLanguage = ref(null)
    const factsheetActive = ref(null)
    const factsheetMarket = ref(null)


    store.dispatch(Actions.MARKETS_LIST_CACHE, {});
    store.dispatch(Actions.LANGUAGES_LIST_CACHE, {});

    const myDetails2 = computed(() => {
      return store.getters.hotelDetails;
    });
    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });
    const myLanguages = computed(() => {
      return store.getters.LanguagesListCache;
    });



    const newFactSheetData = ref<NewFactSheetData>({
      factsheetTitle: "",
      factsheetMarket: "",
      factsheetLanguage: "",
      factsheetActive: "",
    });

    const validationSchema = Yup.object().shape({
      factsheetTitle: Yup.string().required().label("Fact-Sheet İsmi"),
      factsheetMarket: Yup.string().required().label("Market"),
      factsheetLanguage: Yup.string().required().label("Dil"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      let isActive = (factsheetActive.value.checked) ? 'active' : 'passive';

      const payload1 = {
        HotelID: myDetails2.value.ID,
        Title: newFactSheetData.value.factsheetTitle,
        Market: newFactSheetData.value.factsheetMarket,
        Language: newFactSheetData.value.factsheetLanguage,
        is_active: isActive,
      }
      console.log(payload1);

      store.dispatch(Actions.HOTEL_FACTSHEETS_INSERT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              // Activate indicator
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(newFactSheetModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getUserErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        // Activate indicator
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });


    };
    const setMarket = (e) => {
      newFactSheetData.value.factsheetMarket = e.target.selectedOptions[0].dataset.short;
    }
    const setLanguage = (e) => {
      newFactSheetData.value.factsheetLanguage = e.target.selectedOptions[0].dataset.short;
    }
    return {
      myDetails2,
      myMarkets,
      myLanguages,
      newFactSheetData,
      factsheetActive,
      factsheetMarket,
      factsheetLanguage,
      validationSchema,
      submit,
      submitButtonRef,
      newFactSheetModalRef,
      setMarket,
      setLanguage,
    };
  },
});
