
import { defineComponent } from "vue";

export default defineComponent({
  name: "room-price-action",
  components: {
  },
  props:{
    hotelID: String,
  },
});
