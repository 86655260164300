
import {computed, defineComponent} from "vue";
import FactSheetAddNew from "@/components/hotel/FactSheetAddNew.vue";
import FactSheetUpdate from "@/components/hotel/FactSheetUpdate.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "kt-widget-10",
  components: {
    FactSheetAddNew,
    FactSheetUpdate,
  },
  data: function () {
    return {
      myFactsheetID : 0,
    }
  },
  methods: {
    setFactsheetID(ID){
      this.myFactsheetID = ID;
    },

  },
  props: {
    widgetClasses: String,
    hotelID: String,
  },
  setup(props) {
    const payload = {
      HotelID: props.hotelID
    }
    store.dispatch(Actions.HOTEL_FACTSHEETS_LIST, payload);
    const myFactsheets = computed(() => {
      return store.getters.hotelFactSheetsList;
    });
    const deleteFactsheet = (ID) => {

      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload1 = {
            ID: ID,
            HotelID: props.hotelID
          }
          console.log(payload1);
          store.dispatch(Actions.HOTEL_FACTSHEETS_DELETE, payload1)
              .then(() => {
                Swal.fire(
                  'Silindi!',
                  'Kayit silme işlemi tamamlandı.',
                  'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getHotelFactsheetsErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });
    };
    return {
      myFactsheets,
      deleteFactsheet,
    };
  },
});
